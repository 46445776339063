<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>

        <div class="white-bg">
          <div class="news-main">
            <div class="div_ExamList_all com_Box">
              <div class="Tit">
                <div class="Tit-title">所有报名</div>
              </div>
              <div class="List">
                <div
                  v-for="(item, i) in examList"
                  :key="i"
                  class="flexList div_enrollList"
                  @click="goToLogin"
                >
                  <div
                    class="div_enrollList_tag"
                    :class="item.isOpen ? 'tag-success' : 'tag-danger'"
                  >
                    <span class="enrollList-text">{{
                      item.statusText
                    }}</span>
                  </div>
                  <div class="div_enrollList_center line1">{{ item.ksmc }}</div>
                  <div class="div_ExamList_time">
                    <i class="iconfont icon-shijian mr-2"></i>报名时间：{{
                      item.bmkssj
                    }}至{{ item.bmjssj }}
                  </div>
                  <div class="div_ExamList_button ExamList_button_info">
                    <span class="enrollList-text">{{
                      item.statusText
                    }}</span>
                  </div>
                </div>
              </div>

              <div style="padding: 30px">
                <el-pagination
                  @size-change="handleSizeChange"
                  background
                  @current-change="handleCurrentChange"
                  :current-page="query.pageNum"
                  :page-size="query.pageSize"
                  layout="total, sizes,jumper, prev, pager, next"
                  :total="query.allCount"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./index-header.vue";
import Footer from "./index-footer.vue";
import Navbar from "./navbar.vue";
import commonPagination from "../common/commonPagination";

import { getExam } from "@/api/web/web.js";

export default {
  name: "index",
  data() {
    return {
      query: {
        nowPage: 1,
        pageSize: 10,
        allCount: 0,
        isPaging: 1, // 分页请求
      },
      examList: [],
    };
  },
  components: {
    Header,
    Navbar,
    Footer,
    commonPagination,
  },
  methods: {
    /**
     * 获取考试报名
     */
    getEaxmList() {
      getExam(this.query).then((res) => {
        if (res.status) {
          let list = res.data.data;
          list.forEach((item) => {
            let date = new Date().getTime();
            let kssj = item.bmkssj ? item.bmkssj.replace(/-/g, "/") : "";
            let jssj = item.bmjssj ? item.bmjssj.replace(/-/g, "/") : "";
            kssj = new Date(kssj).getTime();
            jssj = new Date(jssj).getTime();
            if (kssj < date && jssj > date) {
              this.$set(item, "statusText", "正在报名");
              this.$set(item, "isOpen", true);
              console.log(item);
            } else {
              this.$set(item, "isOpen", false);
              if (kssj > date) {
                this.$set(item, "statusText", "报名未开始");
              }else{
                this.$set(item, "statusText", "报名结束")
              }
            }
            item.bmkssj =
              item.bmkssj != null ? item.bmkssj.substring(0, 16) : "";
            item.bmjssj =
              item.bmjssj != null ? item.bmjssj.substring(0, 16) : "";
          });
          this.examList = list;
          this.query.allCount = res.data.total;
        }
      });
    },

    /**
     * 跳转登录
     */
    goToLogin() {
      window.location.href = "/login";
    },

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getEaxmList();
    },
    handleCurrentChange(val) {
      this.query.pageNum = val;
      this.getEaxmList();
    },
  },
  mounted() {
    this.getEaxmList();
  },
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/index.css";
@import "~@/assets/css/list.css";
</style>
<style type="text/css" scoped>
.center-list-title .iconfont {
  color: #2886bd;
}
.news-main {
  min-height: 550px;
}
</style>
